// Firebase
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import '../api';

export interface Program {
  jobID?: string;
  builderID: string;
  name: string;
  imageUrl: string;
  portMap: number[];// Maybe turn this into a array of strings
  zones: { duration: number; cycles: number }[];
  sets:number,
  singlePump:boolean,
  /** If this device has a dummy port swapping thing...*/
  dummyDevice: boolean;
  lastModifiedDate: firebase.firestore.Timestamp;
}

/** The global zone mapping array. Stores the zone map for a given number of zones */
export const zoneValues: number[][] = [
  // Zones 1-3 empty
  [],
  [],
  [],
  // 4 Zone mapping
  [
    0,
    0,
    1,
    1,
    2,
    2,
    3,
    // Whirlpool
    3,
  ],
  // 5 Zone mapping
  [
    0,
    0,
    1,
    1,
    2,
    2,
    3,
    // Whirlpool
    4,
  ],
  // 6 Zone mapping
  [
    0,
    0,
    1,
    1,
    2,
    3,
    4,
    // Whirlpool
    5,
  ],
  // 7 Zone mapping
  [
    0,
    0,
    1,
    2,
    3,
    4,
    5,
    // Whirlpool
    6,
  ],
  // 8 Zone mapping
  [
    0,
    1,
    2,
    3,
    4,
    5,
    6,
    // Whirlpool
    7,
  ],
  // SLAVE DEVICE SECTION(15 ports)
  // 9 Zone mapping
  [
    0,
    1,
    2,
    3,
    4,
    5,
    // SLAVE PORTS
    6,
    6,
    6,
    6,
    7,
    7,
    7,
    7,
    // Whirlpool
    8,
  ],
  // 10 Zone mapping
  [
    0,
    1,
    2,
    3,
    4,
    5,
    // SLAVE PORTS
    6,
    6,
    6,
    6,
    7,
    7,
    8,
    8,
    // Whirlpool
    9,
  ],
  // 11 Zone mapping
  [
    0,
    1,
    2,
    3,
    4,
    5,
    // SLAVE PORTS
    6,
    6,
    7,
    7,
    8,
    8,
    9,
    9,
    // Whirlpool
    10,
  ],
  // 12 Zone mapping
  [
    0,
    1,
    2,
    3,
    4,
    5,
    // SLAVE PORTS
    6,
    6,
    7,
    7,
    8,
    8,
    9,
    10,
    // Whirlpool
    11,
  ],
  // 13 Zone mapping
  [
    0,
    1,
    2,
    3,
    4,
    5,
    // SLAVE PORTS
    6,
    6,
    7,
    7,
    8,
    9,
    10,
    11,
    // Whirlpool
    12,
  ],
  // 14 Zone mapping
  [
    0,
    1,
    2,
    3,
    4,
    5,
    // SLAVE PORTS
    6,
    6,
    7,
    8,
    9,
    10,
    11,
    12,
    // Whirlpool
    13,
  ],
  // 15 Zone mapping
  [
    0,
    1,
    2,
    3,
    4,
    5,
    // SLAVE PORTS
    6,
    7,
    8,
    9,
    10,
    11,
    12,
    13,
    // Whirlpool
    14,
  ],
];

export const defaultProgram: Program = {
  jobID: "",
  builderID: "",
  name: "",
  imageUrl: "",
  portMap: [0, 0, 0, 0, 0, 0, 0, 0],
  zones: [
    { cycles: 18, duration: 30 },
    { cycles: 18, duration: 30 },
    { cycles: 18, duration: 30 },
    { cycles: 18, duration: 30 },
  ],
  sets:0,
  dummyDevice: false,
  singlePump:false,
  lastModifiedDate: firebase.firestore.Timestamp.now(),
};
