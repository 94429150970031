
import { appState } from "@/main";
import { algoliaJobSearch, getJob, getJobs, JobState, result } from "@/ts/api";
import { f7 } from "framework7-vue";
import { Router } from "framework7/types";
import { defineComponent, PropType, Ref, ref, watchEffect } from "vue";

interface Tab {
  name: string;
  description?: string;
  filter: JobState;
  data?: result[];
}

const adminTabs: Tab[] = [
  {
    name: "Approvals",
    filter: JobState.Staging,
  },
  {
    name: "Completed",
    filter: JobState.Purchased,
  },
];

const builderTabs: Tab[] = [
  {
    name: "Drafts",
    description: "Projects pending submittal for design",
    filter: JobState.Draft,
  },
  {
    name: "Ready",
    description: "Projects designed pending approval",
    filter: JobState.Finalized,
  },
  {
    name: "Purchased",
    description: "Projects Ordered",
    filter: JobState.Purchased,
  },
];

export default defineComponent({
  props: {
    f7route: Object as PropType<Router.Route>,
    f7router: Object as PropType<Router.Router>,
  },
  watch: {
    appState(newAppState, oldAppState) {
      console.log("NEW APPSTATE:", oldAppState);
      console.log("NEW APPSTATE:", newAppState);
    },
  },

  setup(props) {
    const loading = ref(false);
    const results: Ref<result[]> = ref([]);
    const tabs: Ref<Tab[]> = ref(appState.admin ? adminTabs : builderTabs);
    const activeTab: Ref<Tab> = ref(tabs.value[appState.admin ? 0 : 1]);
    const searchQuery = ref("");

    appState.activeJob = props.f7route?.params.jobID ?? "new";

    if (
      appState.activeJob != undefined &&
      appState.activeJob != "all" &&
      appState.activeJob != "new"
    ) {
      getJob(appState.activeJob).then((job) => {
        if (job != null) activeTab.value.filter = job?.state;
      });
    }
    return {
      results: results,
      loading: loading,
      tabs: tabs,
      activeTab: activeTab,
      searchQuery: searchQuery,
    };
  },
  mounted() {
    appState.panelRefreshCallback = () => {
      this.refresh();
      return;
    };
    // Setup watcher for searching
    watchEffect(() => {
      let query = this.searchQuery.toString();

      // this is run on initial mount and on search clear
      if (query === "") {
        this.loading = false;
        this.changeTab(this.activeTab.filter);
        return;
      }

      this.loading = true;
      this.results = [];
      // ensure user is done typing before searching
      setTimeout(() => {
        let newQuery = this.searchQuery.toString();
        if (query != newQuery) return;
        this.search(query);
      }, 500);
    });
  },
  methods: {
    search: async function (query: string) {
      try {
        let _results: result[] = [];
        if (appState.admin) {
          _results = await algoliaJobSearch({
            query: query,
            states: appState.admin ? [JobState.Staging, JobState.Finalized, JobState.Purchased] : [], // TODO add a filtering selection to the page?
          });
        } else {
          //TODO fix job search for builders to not show all
          let _jobs = await getJobs([
            JobState.Draft,
            JobState.Finalized,
            JobState.Purchased,
          ]);

          // Filter by job name (case insensitive) or id (exact match)
          _jobs.forEach((_job) => {
            if (_job.name.toUpperCase().indexOf(query.toUpperCase()) != -1 || _job.ID === query) {
              _results.push(_job.toJobResult());
            }
          });
        }

        // Sort all results alphabetically
        _results.sort((a, b) => (a.title.localeCompare(b.title)));

        this.results = _results;
      } catch (e) {

        this.results = [];
        f7.toast.show({
          text: "Failed to retrieve results, please try again later or contact support.",
          closeTimeout: 6000,
          closeButton: true,
        });
      }
      this.loading = false;

    },
    async changeTab(newFilter: string) {
      this.searchQuery = "";

      // find a tab with a matching filter
      let t: Tab | undefined = this.tabs.find((t) => t.filter === newFilter);
      if (t == undefined) return;
      this.activeTab = t;

      // only load data if we haven't already cached it
      if (!t.data) {
        this.loading = true;

        t.data = [];
        (await getJobs([t.filter])).forEach((_job) => {
          t!.data!.push(_job.toJobResult());
        });

        this.loading = false;
      }

      this.results = t.data ?? [];
    },
    refresh() {
      this.tabs.forEach((t) => delete t.data);
      this.changeTab(this.activeTab.filter);
    },
  },
  computed: {
    appState: () => appState,
  },
});
