
import BillOfMaterials from "@/components/BillOfMaterials.vue";
import StoreListItem from "@/components/StoreListItem.vue";
import { appState } from "@/main";
import { algoliaBuilderSearch, Builder, getBuilder } from "@/ts/api";
import { currency, getWarehouse, Item } from "@/ts/api/store";
import { defineComponent, onMounted } from "@vue/runtime-core";
import { f7, f7ready } from "framework7-vue";
import { Router } from "framework7/types";
import { PropType, ref } from "vue";
export default defineComponent({
  components: {
    StoreListItem,
    BillOfMaterials,
  },
  props: {
    jobID: String,
    f7route: Object,
    f7router: Object as PropType<Router.Router>,
  },
  setup: () => {
    const items = ref([] as Item[]);
    const loading = ref(true);
    const builderID = ref("");
    onMounted(() => {
      f7ready((app) => {
        getWarehouse()
          .then((res) => {
            items.value = res.filter((i) => i.sku.length > 0);
            loading.value = false;
          })
          .catch((e) => {
            console.error(e);
            loading.value = false;
            app.views.main.router.navigate("/");
            app.dialog.alert(
              "There was an error getting warehouse parts. Please contact support."
            );
          });

        let autocomplete = app.autocomplete.create({
          inputEl: "#builder-id",
          openIn: "dropdown",
          preloader: true,
          source: function (query, render) {
            autocomplete.preloaderShow();
            algoliaBuilderSearch({
              query: query,
            }).then((e) => {
              autocomplete.preloaderHide();
              // Render items by passing array with result items
              render(e.map((i) => `${i.id} (${i.title})`));
            });
          },
          on: {
            close: function (a) {
              let v = a.value as string[];
              // If the current field has a autocomplete suggestion
              if (v.length > 0) builderID.value = v[0].split(" ")[0];
            },
          },
        });

        app.autocomplete.create({
          inputEl: "#builder-id-mobile",
          openIn: "dropdown",
          preloader: true,
          source: function (query, render) {
            autocomplete.preloaderShow();
            algoliaBuilderSearch({
              query: query,
            }).then((e) => {
              autocomplete.preloaderHide();
              // Render items by passing array with result items
              render(e.map((i) => `${i.id} (${i.title})`));
            });
          },
          on: {
            close: function (a) {
              let v = a.value as string[];
              // If the current field has a autocomplete suggestion
              if (v.length > 0) builderID.value = v[0].split(" ")[0];
            },
          },
        });
      });
    });
    return {
      items: items,
      loading: loading,
      builderID: builderID,
      isCheckoutLoading: ref(false),
    };
  },
  methods: {
    async checkout() {
      if (appState.admin && this.builderID.length <= 0) {
        f7.dialog.alert(
          "Your user type can only purchase items from the warehouse on the behalf of a builder.",
          "Please enter a builder ID to checkout."
        );
        return;
      }
      let _b: Builder | undefined;
      if (appState.admin) {
        this.isCheckoutLoading = true;
        _b = await getBuilder(this.builderID);
        this.isCheckoutLoading = false;
      } else _b = appState.builderData;

      f7.views.main.router.clearPreviousHistory();
      f7.views.main.router.navigate("/checkout", {
        props: {
          items: this.cart,
          usePackages: false, 
          builder: _b,
        },
      });
    },
  },
  computed: {
    cart(): Item[] {
      return this.items.filter((i) => i.count > 0) as Item[];
    },
    /**
     * Calculates the total price of all items in the cart and returns a USD formatted string
     */
    total(): string {
      if (this.items.length == 0) {
        return "$0.00";
      }
      let total = 0;
      this.items.forEach((i) => (total += i.price * i.count));
      return currency.format(total);
    },
    appState: () => appState,
  },
});
