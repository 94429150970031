
import routes from "@/ts/routes";
import LoginScreen from "@/pages/Login.vue";
import MenuTile from "@/components/MenuPanel/components/MenuTile.vue";
import { Framework7Parameters } from "framework7/types";
import { onMounted, ref } from "vue";
import { f7, f7ready } from "framework7-vue";
import { appState, auth } from "@/main";

export default {
  components: {
    LoginScreen,
    // MenuPanel,
    MenuTile,
  },

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  setup() {
    const f7params: Framework7Parameters = {
      name: "Pul Connect",
      theme: "aurora",
      routes: routes,
      view: {
        masterDetailBreakpoint: 1200,
        browserHistory: true,
        browserHistoryInitialMatch: true,
        reloadDetail: true,
        browserHistoryRoot:
          process.env.NODE_ENV === "development"
            ? "http://localhost:8080"
            : "https://app.pulproducts.com",
        browserHistorySeparator: "",
        browserHistoryStoreHistory: false,
      },
    };
    const detailPreviewHidden = ref(false);
    let currentPageName = ref("home");
    onMounted(() => {
      f7ready((app) => {
        app.views.main.router.on("routeChange", (newRoute) => {
          detailPreviewHidden.value =
            newRoute.name != "jobs" && newRoute.name != "builders";
          currentPageName.value = newRoute.name;
        });
      });
    });

    return {
      f7params,
      detailPreviewHidden,
      currentPageName,
    };
  },

  methods: {
    logout: () => {
      auth.signOut();
      f7.views.main.router.navigate("/", {
        clearPreviousHistory: true,
        reloadAll: true,
      });
    },
  },
  computed: {
    appState: () => {
      return appState;
    },
  },
};
