
import { auth } from "@/main";
import { f7 } from "framework7-vue";
import { defineComponent } from "vue";

export default defineComponent({
  components: {},
  data() {
    return {
      username: "",
      password: "",
      validating: false,
      resettingPassword: false,
      message: "",
    };
  },
  created() {
    auth.onAuthStateChanged((authData) => {
      if (authData) f7.loginScreen.close("#login-screen");
      else f7.loginScreen.open("#login-screen", false);
    });
  },
  methods: {
    signIn(e: any): void {
      e?.preventDefault();
      const isValid =
        !this.validating && f7.input.validateInputs("#login-form");
      if (!isValid) return;
      this.message = "";

      this.validating = true;
      console.log(this.username, this.password);

      auth
        .signInWithEmailAndPassword(this.username, this.password)
        .catch((reason: any) => {
          if (
            reason.code == "auth/user-not-found" ||
            reason.code == "auth/invalid-email"
          )
            this.message = "Invalid email";
          else if (reason.code == "auth/wrong-password")
            this.message = "Invalid password";
          else this.message = reason.message;
          this.validating = false;
        })
        .then((result) => {
          console.log(result);
          if (result) {
            this.validating = false;
          }
        });
    },
    // Enables the password reset elements
    startPasswordReset(e: any): void {
      this.resettingPassword = true;
    },
    // Disables the password reset elements
    stopPasswordReset(e: any): void {
      this.resettingPassword = false;
    },

    resetPassword(e: any): void {
      auth
        .sendPasswordResetEmail(this.username)
        .then(() => {
          this.validating = false;
          this.resettingPassword = false;
          this.message = "";
          alert("Sent password reset email. If you can't find it then check your spam folder.")
        })
        .catch((reason: any) => {
          if (
            reason.code == "auth/user-not-found" ||
            reason.code == "auth/invalid-email"
          )
            this.message = "Invalid email";
          else this.message = reason.message;
          this.validating = false;
        })
        ;
    },
  },
});
