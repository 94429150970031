
import { defineComponent, PropType, ref } from "vue";
import { Item } from "@/ts/api/store";
export default defineComponent({
  methods: {
    hashcodeOf: function (string: string) {
      var hash = 0,
        i, chr;
      if (string.length === 0) return hash;
      for (i = 0; i < string.length; i++) {
        chr = string.charCodeAt(i);
        hash = ((hash << 5) - hash) + chr;
        hash |= 0; // Convert to 32bit integer
      }
      return hash;
    }
  },
  props: {
    readonly: Boolean,
    item: {
      required: true,
      type: Object as PropType<Item>,
    },

  },
});



