
import { defineAsyncComponent, defineComponent, onMounted, Ref, ref, watch } from "vue";
import firebase from "firebase";
import { storageRef } from "@/main";
import { f7 } from "framework7-vue";
import { Job } from "@/ts/api";
import { log } from "firebase-functions/lib/logger";
import { emit } from "process";
export default defineComponent({
  name: "file-upload",
  props: {
    job: Job,
    readonly: Boolean,
    /**
     * Relative directory to read from.
     *
     * e.g. /design_plans or /staging
     */
    dir: String,
    /**
     * Allowed file types
     *
     * e.g. ['dwg', 'pdf']
     */
    allowedTypes: Array,
  },
  setup(props, context) {
    // The current files in the cloud
    const cloudFiles: Ref<firebase.storage.Reference[]> = ref([]);
    const uploadTasks: Ref<firebase.storage.UploadTask[]> = ref([]);

    // Get all current program files
    if (props.job?.ID != null) {
      storageRef
        .child("job_files/" + props.job!.ID + props.dir)
        .listAll()
        .then((res) => {
          res.items.forEach((e) => {
            cloudFiles.value.push(e);
            context.emit('filesLength', cloudFiles.value.length);
          });
        });
    }

    return {
      cloudFiles: cloudFiles,
      uploadTasks: uploadTasks,
      uploadProgress: ref({} as { [fullPathName: string]: number }),
      inputID: Math.random().toString(36).substr(2, 5),
    };
  },
  methods: {

    /**  When the selected files have changed add them to an upload queue*/
    async filesChange(event: any) {
      if (this.job?.ID == null) {
        try {
          await this.job?.save();
        } catch (e) {
          f7.toast.show({
            text:
              (e as Error).message ??
              "There was an error saving, please contact support.",
            closeTimeout: 6000,
            closeButton: true,
          });
          return;
        }
      }
      const files: File[] = event.target.files;
      for (const f of files) {
        // check file type
        if (this.allowedTypes?.length ?? 0 > 0) {
          let split = f.name.split(".");
          const type = split[split.length - 1];
          if (!this.allowedTypes?.includes(type.toLowerCase())) {
            f7.toast.show({
              text: `${f.name} wasn't uploaded because it is the wrong file type`,
              closeButton: true,
              closeTimeout: 5000,
            });
            return;
          }
        }

        let fileRef = storageRef.child(
          `job_files/${this.job!.ID}${this.dir}/(rev. ${this.job!.revision}) ${f.name
          }`
        );
        const _uploadTask: firebase.storage.UploadTask = fileRef.put(f, {
          contentType: "application/octet-stream",
          contentDisposition: "attachment",
        });
        _uploadTask.on(
          firebase.storage.TaskEvent.STATE_CHANGED,
          // on progress update
          (snap) => {
            this.uploadProgress[snap.ref.name] =
              (snap.bytesTransferred / snap.totalBytes) * 100;
          },
          // on error
          (e) => {
            // don't show an error if the user canceled the upload
            if (e.code === "storage/canceled") return;

            f7.toast.show({
              text: `Failed to upload ${_uploadTask.snapshot.ref.name ?? "a file"
                }.`,
            });
          },

          // on finish
          () => {
            this.cloudFiles.push(_uploadTask.snapshot.ref);
            this.uploadTasks.splice(this.uploadTasks.indexOf(_uploadTask), 1);
            this.$emit('filesLength', this.cloudFiles.length);
          }
        );
        this.uploadTasks.push(_uploadTask);
      }
    },
    deleteFile(name: string) {
      console.log("deleting file: " + name);
      // Remove any vars from current files
      this.cloudFiles.forEach((f, i) => {
        if (f.name === name) {
          this.cloudFiles.splice(i, 1);
          f.delete().catch((e) => {
            // put back in array
            this.cloudFiles.splice(i, 0, f);

            f7.toast.show({
              text: `Failed to delete ${f.name}.`,
            });
            console.log("Failed to delete file.", e);
          });
          this.$emit('filesLength', this.cloudFiles.length);

        }
      });
    },
    cancelUpload(task: firebase.storage.UploadTask) {
      task.cancel();
      this.uploadTasks.splice(this.uploadTasks.indexOf(task), 1);
    },
    async viewFile(filename: string) {
      let url = await this.cloudFiles
        .find((e) => e.name == filename)
        ?.getDownloadURL();

      window.open(url, "_self");
    },
    /** Confirms at least one of the uploaded files is a design file */
    validate(): boolean {
      return this.cloudFiles.length > 0;
    },
  },
  computed: {
    noFiles(): boolean {
      return this.cloudFiles.length == 0 && this.uploadTasks.length == 0;
    },
    /**
     * returns all allowed types formatted to a native 'accept' attribute string
     */
    accept(): string {
      return this.allowedTypes?.map((t) => "." + t).join(",") ?? "";
    },
  },
});
