
import { Job, Program, zoneValues } from "@/ts/api";
import { f7 } from "framework7-vue";
import { defineComponent, PropType } from "vue";
import * as csvParser from "papaparse";
import { log } from "firebase-functions/logger";

export interface ImportedProgram {
  portMap: number[];
  zones: { duration: number; cycles: number }[];
  singlePump: boolean;
  sets: number;
}

export default defineComponent({
  props: {
    readonly: Boolean,
  },
  emits: {
    import(payload: ImportedProgram) {
      return payload;
    },
  },
  methods: {
    importProgram(e: any) {
      const file = e.target.files[0];
      if (!file) {
        f7.toast.show({
          text: "File not found.",
          closeTimeout: 1000,
        });
        return;
      }
      csvParser.parse(file, {
        complete: (result) => {
          let data = result.data as Array<Array<string>>;
          console.log(result);

          let program: ImportedProgram = {
            portMap: [],
            zones: [],
            singlePump: (data[5][1] as string).toUpperCase() == "SINGLE PUMP",
            sets: Number.parseInt(data[14][4]),
          };
          console.log("IMPORT single pump " + program.singlePump);

          // import port map
          for (let i = 3; i < 19; i++) {
            try {
              const _data = data[i][9] as string;
              if (_data != "" && _data != null) {
                const _mapVal = Number.parseInt(_data);
                program.portMap.push(_mapVal - 1);
              }
            } catch (error) {
              console.log("Could not parse a port map value");
            }
          }

          // get number of zones
          let _zones = Number.parseInt(data[3][1]);

          let _secondaryPort = program.singlePump ? 5 : 6;// The port that should be used for the secondary valve 
          let _secondarySys = _zones > 8;// If this system has a secondary valve

          // if there is a whirlpool zone then don't calculate that in the zone loop
          if (program.singlePump) {
            _zones -= 1;
          }

          // iterate through each zone
          for (let i = 0; i < _zones; i++) {
            // if this is a secondary zone
            if (_secondarySys&& i>_secondaryPort) {
              // Get the data from the secondary zone section
              program.zones.push({
                duration: Number.parseInt(data[12][5]),
                cycles: Number.parseInt(data[12][4]),
              });
            } else {// Normal Zones
              // start at the 12th row
              let offset = 12;// The row to start at
              program.zones.push({
                duration: Number.parseInt(data[i + offset][2]),
                cycles: Number.parseInt(data[i + offset][1]),
              });
            }
          }

          // add the hydro trough/waterfall zone if needed
          if (program.singlePump) {
            program.zones.push({
              duration: Number.parseInt(data[13][5]),
              cycles: Number.parseInt(data[13][4]),
            });
          }

          console.log("program");
          console.log(program);

          this.$emit("import", program);
        },
      });
    },
  },
});
