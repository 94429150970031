import { Builder, getBuilder, Program } from "@/ts/api";
// Firebase
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";
import "firebase/storage";
// Import Framework7-Vue Plugin
import Framework7Vue, { registerComponents } from "framework7-vue/bundle";
// Import Framework7 Styles
import "framework7/framework7-bundle.css";
// Import Framework7
import Framework7 from "framework7/lite-bundle";
import { createApp, reactive } from "vue";
import App from "./App.vue";
// Import Icons and App Custom Styles
import "./css/icons.css";
import "./css/style.css";

export const NODE_SERVER_URL = "https://pulproducts.herokuapp.com";
// export let NODE_SERVER_URL = "http://localhost";

const firebaseConfig = {
  apiKey: "AIzaSyAu-zttNczZ75DhIiWQb_54cCkE3EKq0H4",
  authDomain: "fancy-pools.firebaseapp.com",
  projectId: "fancy-pools",
  storageBucket: "fancy-pools.appspot.com",
  messagingSenderId: "115716574901",
  appId: "1:115716574901:web:eaca99ff0c4047932738fc",
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export const db = firebase.firestore();
export const auth = firebase.auth();
export const storage = firebase.storage();
export const storageRef = storage.ref();
export const functions = firebase.functions();

// Firebase Functions declaration
export const createBuilderFunction = functions.httpsCallable("createBuilder");
export const deleteBuilderFunction = functions.httpsCallable("deleteBuilder");
export const getBuilderFunction = functions.httpsCallable("getBuilder");
export const importSalesOrder = functions.httpsCallable("importSalesOrder");
export const getCCData = functions.httpsCallable("getCCData");

interface AppState {
  admin: boolean;
  programs: Program[];
  userID: string; // TODO change to general ID
  email?: string;
  builderData?: Builder;
  activeJob: string;
  activeBuilder: string;
  panelRefreshCallback: any;
}
// if (
//   process.env.NODE_ENV === "development" &&
//   process.env.VUE_APP_USE_EMULATORS === "true"
// ) {
  // NODE_SERVER_URL = "http://localhost";
  // functions.useEmulator("localhost", 5001);
// }

/**
 * @param admin  If the signed in user is an admin
 *
 * */
export const appState: AppState = reactive({
  admin: false,
  programs: [],
  userID: "",
  builderData: undefined,
  activeJob: "new",
  activeBuilder: "new",
  panelRefreshCallback: null,
});

// export let panelRefreshCallback:any;

export const init = async () => {
  appState.panelRefreshCallback = null;
  if (!_initAuth) {
    _initAuth = new Promise<null>((resolve) => {
      auth.onAuthStateChanged(async (cred) => {
        // Reset the relevant appState values
        appState.admin = false;
        appState.programs = [];
        appState.userID = "";
        appState.builderData = undefined;

        if (cred?.uid != null) {
          appState.userID = cred.uid;
          appState.email = cred.email ?? undefined;
          // Get the users token info
          const _tokenResult = await cred.getIdTokenResult();

          if (_tokenResult.claims.admin == true) {
            appState.admin = true;
          } else {
            // Builder
            const builderData = await getBuilder(appState.userID);

            if (builderData != null) {
              if (!builderData.savedAddresses) builderData.savedAddresses = {};
              appState.builderData = builderData;
              appState.admin = false;
            }
          }
        } else {
          //TODO logged out KILL ALL LISTENERS
          // programListener();
        }
        resolve(null);
      });
    });
  }

  return await _initAuth;
};

let _initAuth: Promise<null>;

// let programListener: { (): void; (): void };

// Init Framework7-Vue Plugin
Framework7.use(Framework7Vue);

// Init App
export const app = createApp(App);

// Register Framework7 Vue components
registerComponents(app);

// Mount the app
init().then(() => app.mount("#app"));
