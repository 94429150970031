
import { defineComponent, PropType, ref } from "vue";
export default defineComponent({
  props: {
    href: String,
    selected: Boolean,
    title: String,
    icon: String,
    badge: String,
  }
});
