
import {
  defaultEngineeringForm as DEF,
  EngineeringFormData,
  Job,
  JobState,
} from "@/ts/api";
import { f7 } from "framework7-vue";
import { defineComponent, ref } from "vue";
import fileUpload from "./FileUpload.vue";
interface Check {
  description: string;
  optional: boolean;
  value: string;
}

export default defineComponent({
  components: {
    fileUpload,
  },
  props: {
    job: Job,
    readonly: Boolean,
  },
  mounted() {
    // load the checklist items
    for (let i = 0; i < this.checklist.length; i++) {
      const e = this.checklist[i];
      if (
        this.$props.job?.engineeringForm?.checkboxValues != undefined &&
        i < this.$props.job?.engineeringForm?.checkboxValues.length
      ) {
        e.value = this.$props.job?.engineeringForm?.checkboxValues[i];
      }
      console.log("Setting checkbox to: " + e.value);
    }
  },
  setup(props) {
    const checklistItems = [
      {
        description: "Do the plans include all pool measurements?",
        optional: false,
        value: "",
      },
      {
        description: "Do the plans include locations of any break lines?",
        optional: true,
        value: "",
      },
      {
        description:
          "Do the plans include depths of all steps, baja steps, benches or similar features?",
        optional: true,
        value: "",
      },
      {
        description:
          "Do the plans include the equipment pad and in-floor valve location?",
        optional: false,
        value: "",
      },
      {
        description: "Do the plans include all pool edge types and locations? (negative edge, walk-in)",
        optional: true,
        value: "",
      },
      {
        description: "Is your design set scale (1/4 inch or 1/8 inch)",
        optional: false,
        value: "",
      },
      {
        description:
          "Do the plans include all drain locations? (Note: all drains must be PUL approved drains to qualify for 99% Performance Guarantee.)",
        optional: false,
        value: "",
      },
      {
        description:
          "Do the plans include spa debris removal type? (spillover or debris collection drain)",
        optional: true,
        value: "",
      },
    ] as Check[];

    // load the checklist items
    for (let i = 0; i < checklistItems.length; i++) {
      const e = checklistItems[i];
      if (
        props.job?.engineeringForm?.checkboxValues != undefined &&
        i < props.job?.engineeringForm?.checkboxValues.length
      ) {
        e.value = props.job?.engineeringForm?.checkboxValues[i].toString();
      }
      console.log("Setting checkbox to: " + e.value);
    }

    return {
      id: ref(props.job?.ID ?? ""),
      name: ref(props.job?.name ?? ""),
      phoneNumber: ref(props.job?.engineeringForm.phoneNumber ?? ""),
      projectAddress: ref(
        props.job?.engineeringForm?.projectAddress ?? DEF.projectAddress
      ),
      checklist: ref(checklistItems),
      filesComponent: ref(null as typeof fileUpload | null),
      pumpType: ref(props.job?.engineeringForm?.pumpType ?? DEF.pumpType),
      drainType: ref(props.job?.engineeringForm?.drainType ?? DEF.drainType),
      pumpModel: ref(props.job?.engineeringForm?.pumpModel ?? DEF.pumpModel),
      filterType: ref(props.job?.engineeringForm?.filterType ?? DEF.filterType),
      filterModel: ref(
        props.job?.engineeringForm?.filterModel ?? DEF.filterModel
      ),
      spaHeads: ref(props.job?.engineeringForm?.spaHeads ?? DEF.spaHeads),
      bajaFixtures: ref(
        props.job?.engineeringForm?.bajaFixtures ?? DEF.bajaFixtures
      ),
      color: ref(props.job?.engineeringForm?.color ?? DEF.color),
      finish: ref(props.job?.engineeringForm?.finish ?? DEF.finish),
      notes: ref(props.job?.engineeringForm?.notes ?? DEF.notes),
    };
  },
  watch: {
    form(data) {
      this.setData(data);
    },
  },
  computed: {
    validChecklist(): boolean {
      for (let i = 0; i < this.checklist.length; i++) {
        const c = this.checklist[i];
        if (c.value === "no" || c.value === "") {
          return false;
        }
      }
      return true;
    },
    isDraft(): boolean {
      return this.job?.state == JobState.Draft;
    },
  },
  methods: {
    validate(): boolean {
      // check form
      let _form: HTMLFormElement | null = this.$refs.form as HTMLFormElement;
      if (!_form?.checkValidity()) {
        f7.dialog.alert(
          "Please update the highlighted fields.",
          "Invalid Input"
        );
        return false;
      }
      // check design plan requirements
      if (this.job?.state == JobState.Draft && !this.validChecklist) {
        f7.dialog.alert(
          "You must ensure the design plans meet all the requirements listed.",
          "Design plan requirements not met"
        );
        return false;
      }
      // check for at least one file
      const validFiles = this.filesComponent?.validate() as boolean;
      if (!validFiles) {
        f7.dialog.alert(
          "Please upload the pool design plans before submitting this job.",
          "Missing Files"
        );
        return false;
      }

      return true;
    },
    setData(d: EngineeringFormData) {
      this.phoneNumber = d.phoneNumber ?? DEF.phoneNumber;
      this.projectAddress = d.projectAddress ?? DEF.projectAddress;
      this.pumpType = d.pumpType ?? DEF.pumpType;
      this.pumpModel = d.pumpModel ?? DEF.pumpType;
      this.color = d.color ?? DEF.color;
      this.finish = d.finish ?? DEF.finish;
      this.notes = d.notes ?? DEF.notes;
      this.spaHeads = d.spaHeads ?? DEF.spaHeads;
      this.bajaFixtures = d.bajaFixtures ?? DEF.bajaFixtures;
      // load the checklist items
      for (let i = 0; i < this.checklist.length; i++) {
        const e = this.checklist[i];
        if (d.checkboxValues != undefined && i >= d.checkboxValues.length) {
          e.value = d.checkboxValues[i];
        }
      }
    },
    getData(): EngineeringFormData {
      var data: EngineeringFormData = {
        phoneNumber: this.phoneNumber,
        projectAddress: this.projectAddress,
        pumpType: this.pumpType,
        pumpModel: this.pumpModel,
        filterType: this.filterType,
        drainType: this.drainType,
        filterModel: this.filterModel,
        color: this.color,
        finish: this.finish,
        notes: this.notes,
        spaHeads: this.spaHeads,
        bajaFixtures: this.bajaFixtures,
        checkboxValues: this.checklist.map((e) => e.value),
      };

      return data;
    },
    updateChecklist(i: number, val: string) {
      let c = this.checklist[i];
      c.value = c.value === val ? "" : val;
    },
  },
});
