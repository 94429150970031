
import { appState } from "@/main";
import { defineComponent, watchEffect } from "@vue/runtime-core";

export default defineComponent({
  props: {
    f7route: Object,
    f7router: Object,
  },
  computed: {
    // This "imports" the "reacivity" of appState so that the ui updates
    appState() {
      return appState;
    },
  },
});
