import HomePage from "@/pages/Home.vue";
import Builder from "@/pages/Builders/components/Builder.vue";
import Job from "@/pages/Jobs/components/Job.vue";
import Store from "@/pages/Store/Store.vue";
import NotFoundPage from "@/pages/404.vue";
import Checkout from "@/pages/Checkout/Checkout.vue";
import JobPanel from "@/pages/Jobs/components/Panel.vue";
import BuilderPanel from "@/pages/Builders/components/Panel.vue";

import { Router } from "framework7/types";
import { init } from "@/main";

const routes: Router.RouteParameters[] = [
  {
    name: "home",
    path: "/",
    component: HomePage,
    options: {
      reloadAll: true,
    },
  },
  {
    name: "jobs",
    path: "/jobs",
    component: JobPanel,
    master: true,
    detailRoutes: [
      {
        name: "job",
        path: "/jobs/:jobID",
        component: Job,
      },
    ],
  },
  {
    name: "builders",
    path: "/builders",
    component: BuilderPanel,
    master: true,
    detailRoutes: [
      {
        name: "builder",
        path: "/builders/:builderID",
        component: Builder,
      },
    ],
  },

  {
    name: "store",
    path: "/store",
    component: Store,
  },
  {
    name: "checkout",
    path: "/checkout",
    component: Checkout,
  },
  {
    path: "(.*)",
    component: NotFoundPage,
  },
];

export default routes;
