
import StoreListItem from "@/components/StoreListItem.vue";
import { currency, getItems, Item } from "@/ts/api/store";
import { defineComponent, PropType } from "vue";
export default defineComponent({
  components: {
    StoreListItem,
  },
  props: {
    readonly: Boolean,
    items: {
      required: true,
      type: Object as PropType<Item[]>,
    },
    hideSubtotal: Boolean,
  },
  data() {
    return {
      color: "TBD",
    };
  },
  computed: {
    /**
     * Calculates the total price of all items in the cart and returns a USD formatted string
     */
    subtotal(): string {
      if (this.items.length == 0) {
        return "$0.00";
      }
      let total = 0;
      this.items.forEach((i) => (total += i.price * i.count));
      return currency.format(total);
    },

    getItems(): Item[] {
      return this.items.filter((i) => i.sku[0] !== "J");
    },
    getJets(): Item[] {
      return this.items.filter((i) => i.sku[0] === "J");
    },
    itemColor: {
      get(): string {
        const coloredItems = this.items.filter((item) => item.hasColor);
        if (coloredItems.length == 0) return "";
        const refColor = this.getJets[0].localColor;
        for (let i = 1; i < coloredItems.length; i++) {
          if (!coloredItems[i].matchesColor(refColor)) return "";
        }
        return refColor;
      },
      set(val: string) {
        let dirtyItems: Item[] = [];
        this.items.forEach((item) => {
          try {
            item.color = val;
            dirtyItems.push(item);
          } catch (e) {
            // ignore items that can't be colored
          }
          
          // update the rest of the item info
          getItems(dirtyItems.map(i => i.sku)).then((newValues) => {
            newValues.forEach((newItem) => {
              const oldItem = dirtyItems.find((i) => i.sku === newItem.sku);
              if (oldItem) {
                oldItem.updateItem(newItem);
              }
            });
          });
        });
      },
    },
  },
});
