// Firebase
import { appState, db } from "@/main";
import { FirebaseItem, getItems, Item } from "@/ts/api/store";
import firebase from "firebase/app";
import "firebase/storage";
import { defaultProgram, JobResult, Program } from "../api";

/** Contains the states a job can be in */
export enum JobState {
  Draft = "draft",
  Staging = "staging",
  Finalized = "finalized",
  Purchased = "purchased",
}

/** Contains all the needed info for a Job */
// export interface Job {
//   ID?: string;
//   state: JobState;
//   name: string;
//   builderID: string;
//   program: Program;
// }
export class Job {
  ID?: string;
  state: JobState;
  name: string;
  revision: number;
  builderID: string;
  program: Program;
  engineeringForm: EngineeringFormData;
  bom: Item[];
  submissionDate?: firebase.firestore.Timestamp;

  constructor() {
    this.state = JobState.Draft;
    this.name = "";
    this.revision = 1;
    this.builderID = !appState.admin ? appState?.userID ?? "" : "";
    this.program = defaultProgram;
    this.engineeringForm = defaultEngineeringForm;
    this.bom = [];
  }

  /** From firebase doc  */
  static async fromDoc(doc: firebase.firestore.DocumentSnapshot) {
    const _data = doc.data()!;
    const _job = new Job();
    _job.ID = doc.id;
    _job.builderID = _data.builderID;
    _job.name = _data.name;
    _job.revision = _data.revision ?? 1;
    _job.program = _data.program as Program;
    _job.engineeringForm = (_data.engineeringForm ?? {}) as EngineeringFormData;
    _job.submissionDate = _data.submissionDate
      ? _data.submissionDate as firebase.firestore.Timestamp
      : undefined;

    const _bomData = (_data.bom ?? []) as Array<any>;
    // get the skus
    const _skus: string[] = _bomData?.map((v, i) => v.sku);

    _job.bom = await getItems(_skus);
    // after we've got the item data then add the count and notes
    for (let i = 0; i < _bomData.length; i++) {
      const _itemData = _data.bom[i] as FirebaseItem;
      const _item = _job.bom.find((e) => e.sku == _itemData.sku); // find the item
      // console.log("FOO: " + JSON.stringify(_item));

      // const _item = _job.bom.find((e) => e.sku == "VAL-CA-01");
      // if the item is found then set the count and notes
      if (_item != undefined) {
        _item.updateCount(_itemData.count);
        _item.notes = _itemData.notes;
      }
    }

    // _job.bom.find((e) => e.sku == 'VAL-CA-01');
    // console.log('FOO:');
    // console.log(_job.bom.find((e) => e.sku == 'VAL-CA-01'));

    _job.state = _data.state;

    return _job;
  }

  /** Cleans the bom
   *
   * * Called before saving the job or submitting an order
   */
  cleanBOM() {
    const _cleanBom: Item[] = [];
    this.bom.forEach((e) => {
      if (!e.notes) e.notes = "";
      if (e.count > 0) {
        _cleanBom.push(e);
      }
    });
    this.bom = _cleanBom;
  }

  /** Checks if the BOM is valid */
  BOMvalid() {
    let headColor = null;
    for (const item of this.bom) {
      if (item.hasError) return false;

      // make sure all jet heads are the same color
      if (item.sku[0] === "J") {
        if (!headColor) headColor = item.color;
        else if (headColor !== item.color) return false;
      }
    }
    return true;
  }

  /** Checks if the program is valid */
  programValid() {
    return this.program.sets > 0;
  }


  /** Converts this Job to a JobResult */
  toJobResult() {
    const _jobResult: JobResult = {
      id: this.ID ?? "",
      link: `/jobs/${this.ID ?? ""}`,
      state: this.state,
      subtitle: this.ID ?? "",
      title: this.name,
    };
    return _jobResult;
  }

  /** From Job to object
   * * For firebase*/
  toObject() {
    const _object: any = {};
    _object.ID = this.ID;
    _object.builderID = this.builderID;
    _object.name = this.name;
    _object.revision = this.revision;
    _object.program = this.program as Program;
    _object.engineeringForm = this.engineeringForm as EngineeringFormData;
    if (this.submissionDate) _object.submissionDate = this.submissionDate;
    // _object.bom = this.bom as Item[];
    // convert BOM to object
    const _bom: any[] = [];
    this.bom.forEach((_item) => {
      _bom.push(_item.toObject());
    });
    _object.bom = _bom;

    _object.state = this.state;

    return _object;
  }

  copy() {
    const _copy = new Job();
    _copy.ID == this.ID;
    _copy.state = this.state;
    _copy.name = this.name;
    _copy.revision = this.revision;
    _copy.builderID = this.builderID;
    _copy.program = this.program; //TODO DEEPCOPY
    _copy.engineeringForm = this.engineeringForm; // TODO DEEPCOPY
    _copy.bom = [...this.bom];
    _copy.submissionDate = this.submissionDate;
    return _copy;
  }

  /** Saves/Creates a new job */
  async save() {
    const _jobID = this.ID;
    this.cleanBOM();
    // Copy the job to an object and remove the vars that aren't used by firebase
    const _jobObject = this.toObject(); //TODO perhaps change to a to firebase function
    delete _jobObject.ID;

    if (_jobID != null && _jobID != "") {
      // Edit job
      await db.collection("jobs").doc(_jobID).set(_jobObject);
    } else {
      // Create new job
      if (!appState.admin) _jobObject.builderID = appState.userID;
      if ((_jobObject.builderID ?? "") === "")
        throw new Error("Builder ID field is required");
      const _doc = await db.collection("jobs").add(_jobObject);
      _jobObject.ID = _doc.id; //TODO may want a getFromDoc type function
      this.ID = _doc.id;
    }
    // window.location.reload();
    appState.panelRefreshCallback();
    return _jobObject;
  }
}

/** Gets all jobs with the given state
 *  If the signed in user is a builder it will only return that builders jobs
 */
export async function getJobs(states: JobState[]): Promise<Job[]> {
  let _query: firebase.firestore.Query<firebase.firestore.DocumentData> =
    db.collection("jobs");
  if (!appState.admin) {
    _query = _query.where("builderID", "==", appState.userID);
  }
  const _states: string[] = states;
  _query = _query.where("state", "in", _states);

  const _docs = (await _query.get()).docs;
  const _jobs: Job[] = [];
  for (let i = 0; i < _docs.length; i++) {
    const _doc = _docs[i];
    _jobs.push(await Job.fromDoc(_doc));
  }

  return _jobs;
}

/** Gets a job via ID */
export async function getJob(ID: string) {
  try {
    if (ID == "") return null;
    const _doc = await db.collection("jobs").doc(ID).get();
    if (!_doc.exists) return null;
    const _job: Job = await Job.fromDoc(_doc);
    _job.ID = ID;
    return _job;
  } catch (e) {
    console.error("Failed to get job.", e);
    return null;
  }
}

/** Submit the job
 * * Moves the job from draft to staging
 */
export async function submitJob(job: Job) {
  job.state = JobState.Staging;
  job.submissionDate = firebase.firestore.Timestamp.now();
  await job.save();
}
/** Finalizes the job by setting its state to finalized */
export async function finalizeJob(job: Job) {
  job.state = JobState.Finalized;
  await job.save();
}

/** Deletes the job */
export async function deleteJob(job: Job) {
  console.log("Deleting job: " + JSON.stringify(job));
  await db.collection("jobs").doc(job.ID).delete();
}

export interface EngineeringFormData {
  projectAddress: {
    address: string;
    city: string;
    state: string;
    zip: string;
  };
  phoneNumber: string;
  pumpType: string;
  pumpModel: string;
  filterType: string;
  drainType: string;
  filterModel: string;
  color: string;
  finish: string;
  notes: string;
  spaHeads: string;
  bajaFixtures: string;
  checkboxValues?: string[];
}

export const defaultEngineeringForm: EngineeringFormData = {
  projectAddress: {
    address: "",
    city: "",
    state: "",
    zip: "",
  },
  phoneNumber: "",
  pumpType: "",
  pumpModel: "",
  drainType:"",
  filterType: "",
  filterModel: "",
  color: "",
  finish: "",
  notes: "",
  spaHeads: "",
  bajaFixtures: "",
};
