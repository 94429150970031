
import { algoliaBuilderSearch, result } from "@/ts/api";
import { f7 } from "framework7-vue";
import { Router } from "framework7/types";
import { defineComponent, PropType, Ref, ref, watch, watchEffect } from "vue";
import { appState } from "../../../main";

export default defineComponent({
  props: {
    f7route: Object as PropType<Router.Route>,
  },
  setup(props) {
    const fetching = ref(true);
    const results: Ref<result[]> = ref([]);
    const searchQuery = ref("");
    appState.activeBuilder = props.f7route?.params.builderID ?? "new";

    // watchEffect(() => {
    //   const _id = props.f7route?.params.builderID;
    //   console.log(_id);
    //   activeID.value = _id ?? "";
    // });
    return {
      results: results,
      fetching: fetching,
      searchQuery: searchQuery,
    };
  },
  mounted() {
     
    // Setup watcher for searching
    watchEffect(() => {
      let query = this.searchQuery.toString();

      this.fetching = true;
      this.results = [];
      // ensure user is done typing before searching
      setTimeout(() => {
        let newQuery = this.searchQuery.toString();
        if (query != newQuery) return;
        this.search(query);
      }, 500);
    });
  },
  methods: {
    search: async function (query: string) {
      try {
        let _results: result[] = [];
        _results = await algoliaBuilderSearch({
          query: query,
        });       
        // Sort all results alphabetically
        _results.sort((a, b) => (a.title?.localeCompare(b.title)??-1));
        this.results = _results;
      } catch (e) {
        console.error(e);

        this.results = [];
        f7.toast.show({
          text: "Failed to retrieve results, please try again later or contact support.",
          closeTimeout: 6000,
          closeButton: true,
        });
      }
      this.fetching = false;
    },
    clearSearch() {
      this.searchQuery = "";
      this.search("");
    },
  },
  computed: {
    appState: () => appState,
  },
});
