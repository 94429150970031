import { createVNode as _createVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { for: "program-input" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_f7_button = _resolveComponent("f7-button")

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode("input", {
      style: {"display":"none"},
      type: "file",
      onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.importProgram($event))),
      id: "program-input",
      accept: ".csv"
    }, null, 32),
    _createVNode("label", _hoisted_1, [
      (!_ctx.readonly)
        ? (_openBlock(), _createBlock(_component_f7_button, {
            key: 0,
            outline: "",
            href: false,
            external: true,
            text: "Import from csv"
          }))
        : _createCommentVNode("", true)
    ])
  ], 64))
}